import { HeaderText, CloseButton, ModalBackground, ModalWrapper, ModalHeader, ModalContent} from "./styles/ShellStyles"
import styled from "styled-components";

const PulsatingLink = styled.p`
  font-size: 1.5; /* Use rem for responsive font size */
  color: white;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  animation: heartbeat-glow 2s ease-in-out infinite;

  a {
    color: inherit; /* Inherit the color from MenuItem (bright green) */

    text-decoration: none; /* Remove the default underline from the link */
    display: block; /* Makes the link cover the entire MenuItem */
  }
  @media (max-width: 600px) {
    font-size: 1.5rem; /* Smaller font size on mobile */
  }

  @keyframes heartbeat-glow {
    0%, 100% {
      text-shadow: 0 0 5px #00FF00, 0 0 10px #00FF00, 0 0 15px #39FF14, 0 0 20px #39FF14;
    }
    50% {
      text-shadow: 0 0 10px #00FF00, 0 0 20px #00FF00, 0 0 30px #39FF14, 0 0 40px #39FF14;
    }
  }
`;

export default function AboutModal ({ isOpen, onClose }) {
    return (
      <>
        {isOpen && (
          <ModalBackground isOpen={isOpen}>
            <ModalWrapper>
              <ModalHeader>
                <HeaderText>.../DIRECTORY/ABOUT</HeaderText>
                <CloseButton onClick={onClose}>X</CloseButton>
              </ModalHeader>
              <ModalContent>
              I’ve been a developer for nearly 25 years, with about a decade of 
              professional experience. At 15, I taught myself C++, sparking a 
              lifelong passion for coding. I later attended college for 
              Computer Information Systems, where I learned how to meld 
              technical skills with business needs.
              <br/><br/>
              Professionally, I’ve worked as an engineer in the traditional 
              finance world, building systems and web applications using a 
              variety of frameworks and programming languages.
              <br/><br/>
              I live in rural Michigan with my wife Sara, our two kids, and a 
              lively assortment of dogs, cats, and chickens.              
              <br/><br/>
              [Fun Fact]
              <br/><br/>
              I’ve owned this domain since I was a sophomore in high school. 
              The year was 1999, and I was enamored with the movie The Matrix 
              (surprise, surprise). While the site’s aesthetics have remained consistent, 
              its technology has evolved—from GeoCities, to WordPress PHP, and now to React. 
              It’s amusing how the design has come full circle, transitioning from fresh 
              and cool, to outdated, to cringe-worthy, and back to retro!
              <br/><br/>
              Thanks for visiting my page! 
              <br/><br/>
              <PulsatingLink>        
                <a 
                  href="/static/Matt_Cousineau_Resume_2024.pdf" 
                  download="Matt_Cousineau_Resume.pdf">
                    Click here to download resume.
                </a>
              </PulsatingLink> 
              </ModalContent>
            </ModalWrapper>
          </ModalBackground>
        )}
      </>
    );
  };