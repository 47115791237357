import './App.css';
import ShellCrtModal from './components/ShellCrtModal';
import { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { MatrixEffect } from './components/styles/ShellStyles';

// Global style to prevent scrolling when the modal is open
const GlobalStyle = createGlobalStyle`
  body {
    ${({ isModalOpen }) => isModalOpen && `
      overflow: hidden;
    `}
  }
`;

const PageWrapper = styled.div`
  filter: ${({ isModalOpen }) => (isModalOpen ? 'blur(8px)' : 'none')};
  transition: filter 0.3s ease-in-out;
  pointer-events: ${({ isModalOpen }) => (isModalOpen ? 'none' : 'all')};
  position: relative;
`;

const PulsatingText = styled.p`
  font-size: 2rem; /* Use rem for responsive font size */
  color: #00FF00;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  animation: heartbeat-glow 2s ease-in-out infinite;

  @media (max-width: 600px) {
    font-size: 1.5rem; /* Smaller font size on mobile */
  }

  @keyframes heartbeat-glow {
    0%, 100% {
      text-shadow: 0 0 5px #00FF00, 0 0 10px #00FF00, 0 0 15px #39FF14, 0 0 20px #39FF14;
    }
    50% {
      text-shadow: 0 0 10px #00FF00, 0 0 20px #00FF00, 0 0 30px #39FF14, 0 0 40px #39FF14;
    }
  }
`;

export const OpenMenuButton = styled.button`
  background-color: black;
  color: #00FF00;
  border: 2px solid #00FF00;
  font-size: 1.5rem; /* Make button font-size responsive */
  padding: 10px 20px;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 0;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #00FF00;
    color: black;
    text-shadow: 0 0 10px #00FF00, 0 0 20px #00FF00, 0 0 30px #39FF14;
  }

  &:active {
    transform: scale(0.98);
  }

  @media (max-width: 600px) {
    font-size: 1rem; /* Smaller button font on mobile */
    padding: 8px 16px; /* Adjust padding for smaller screen */
  }
`;

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="App">
      <GlobalStyle isModalOpen={isModalOpen} />
      
      {/* Conditionally render MatrixEffect only when modal is open */}
      {isModalOpen && <MatrixEffect />} 

      <PageWrapper isModalOpen={isModalOpen}>
        <header className="App-header">
          <p>
            <PulsatingText>The personal website of Matt Cousineau</PulsatingText>   
          </p>
          <div>
            <OpenMenuButton onClick={openModal}>Open Menu</OpenMenuButton>
          </div>
        </header>
      </PageWrapper>
      
      <ShellCrtModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default App;