import styled from "styled-components"
import { useRef, useEffect } from "react";

// Modal Background
export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  overflow-y: auto;        /* Allow scrolling if needed */
`;

export const ModalWrapper = styled.div`
  background-color: black;
  border: 4px solid #00FF00;
  width: 80vw;
  max-width: 400px;
  max-height: 90vh;
  overflow-y: auto;
  color: #00FF00;
  text-align: center;
  z-index: 15;
  padding: 20px;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 0;
  margin: auto; /* Ensure it's centered within flex container */

  @media (max-width: 600px) {
    width: 90vw;
    padding: 15px;
  }
`;
export const ModalHeader = styled.div`
  background-color: black;
  color: #00FF00;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #00FF00;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Courier New', Courier, monospace;

  @media (max-width: 600px) {
    font-size: 0.9rem; /* Smaller header text on mobile */
  }
`;

export const ModalContent = styled.div`
  padding: 20px;
  font-family: 'Courier New', Courier, monospace;
  color: #00FF00;

  @media (max-width: 600px) {
    padding: 15px; /* Reduce padding for smaller screens */
  }
`;

// Header Text
export const HeaderText = styled.span`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  position: relative;
  &::after {
    content: '▉'; /* Blinking block cursor */
    font-weight: bold;
    display: inline-block;
    margin-left: 5px;
    animation: blink 1s step-end infinite;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
`;

// Close Button
export const CloseButton = styled.button`
  background: black;
  border: 1px solid #00FF00;
  color: #00FF00;
  font-size: 20px;
  cursor: pointer;
  padding: 5px 10px;
  &:hover {
    background-color: #00FF00;
    color: black;
  }
`;

// Menu List
export const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

// Menu Item
export const MenuItem = styled.li`
  margin: 15px 0;
  font-size: 18px;
  cursor: pointer;
  text-transform: uppercase;
  color: #00FF00; /* Original bright green color */
  &:hover {
    text-shadow: 0 0 10px #00FF00, 0 0 20px #00FF00, 0 0 30px #39FF14;
  }

  /* Styling the anchor tag inside the MenuItem */
  a {
    color: inherit; /* Inherit the color from MenuItem (bright green) */
    text-decoration: none; /* Remove the default underline from the link */
    display: block; /* Makes the link cover the entire MenuItem */
  }
`;

export const MatrixCanvas = styled.canvas`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: black;

  @media (max-width: 600px) {
    width: 100vw;
    height: 100vh; /* Ensure full coverage on mobile */
  }
`;


// Matrix effect logic
export const MatrixEffect = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width = window.innerWidth;
    const height = canvas.height = window.innerHeight;

    const columns = Math.floor(width / 20);
    const drops = Array(columns).fill(1);
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    const draw = () => {
      ctx.fillStyle = "rgba(0, 0, 0, 0.05)";
      ctx.fillRect(0, 0, width, height);

      ctx.fillStyle = "#00FF00"; // Bright green
      ctx.font = "15px monospace";

      drops.forEach((y, x) => {
        const text = characters[Math.floor(Math.random() * characters.length)];
        ctx.fillText(text, x * 20, y * 20);

        if (y * 20 > height && Math.random() > 0.975) {
          drops[x] = 0;
        }
        drops[x]++;
      });
    };

    const interval = setInterval(draw, 50);

    return () => clearInterval(interval); // Cleanup
  }, []);

  return <MatrixCanvas ref={canvasRef} />;
};