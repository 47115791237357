import { HeaderText, CloseButton, MenuList, MenuItem, ModalBackground, ModalWrapper, ModalHeader, ModalContent} from "./styles/ShellStyles"
import { useState } from "react";
import AboutModal from "./AboutModal";

export default function ShellCrtModal ({ isOpen, onClose }) {
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);

  const openModal = () => setIsAboutModalOpen(true);
  const closeModal = () => setIsAboutModalOpen(false);


    return (
      <>
        {isOpen && (
          <ModalBackground isOpen={isOpen}>
            <ModalWrapper>
              <ModalHeader>
                <HeaderText>~/COUSINEAU/DIRECTORY/</HeaderText>
                <CloseButton onClick={onClose}>X</CloseButton>
              </ModalHeader>
              <ModalContent>
                <MenuList>
                  <MenuItem><span>Home</span></MenuItem>
                  <MenuItem onClick={openModal}><span>About Me</span></MenuItem>
                  <MenuItem><span><a href="https://www.github.com/moonwizard69" target="_blank" rel="noopener noreferrer">GitHub</a></span></MenuItem>
                  <MenuItem><span>Projects</span></MenuItem>
                  <MenuItem><span><a href="https://warpcast.com/moonwizard69" target="_blank" rel="noopener noreferrer">Farcaster</a></span></MenuItem>
                  <MenuItem><span><a href="https://www.x.com/wget_matt" target="_blank" rel="noopener noreferrer">X.com</a></span></MenuItem>
                </MenuList>
                <AboutModal isOpen={isAboutModalOpen} onClose={closeModal} />

              </ModalContent>
            </ModalWrapper>
          </ModalBackground>
        )}
      </>
    );
  };